:is([data-layout="vertical"], [data-layout="semibox"])[data-sidebar-size="sm"] .text-lg {
    display: none;
}

/* Add expense form dark theme css start */

[data-bs-theme="dark"] #addExpenseFormSec .expenseform-info.bg_color_10 {
    background: #2f343a !important;
}
[data-bs-theme="dark"] #addExpenseFormSec .react-tags {
    background: #262a30 !important;
}
[data-bs-theme="dark"] #addExpenseFormSec .react-tags__search-input {
    background: inherit !important;
}
[data-bs-theme="dark"] #addExpenseFormSec .react-tags .react-tags__suggestions ul {
    background: #262a30 !important;
}
[data-bs-theme="dark"] #addExpenseFormSec .react-tags .react-tags__suggestions ul li {
    border-bottom: none !important;
}
[data-bs-theme="dark"] #addExpenseFormSec .react-tags .react-tags__selected-tag {
    background: #2f343a !important;
}


[data-bs-theme="dark"] #addExpenseFormSec .form-control:disabled, 
[data-bs-theme="dark"] #addExpenseFormSec .form-control[readonly] {
    background-color: #262a30 !important;
    opacity: 1;
}

/* Add expense form dark theme css end */

/* profit loss and balance sheet css start */
.profit_loss_report {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

/* profit loss and balance sheet css end */
